import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from './Config/config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Preloader } from "./Preloader";

export function Withdrawal() {
    const [WithdrawalAmountBalance, setWithdrawalAmountBalance] = useState('');
    const [UserData, setUserData] = useState({});
    const [loginPassword, setLoginPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const WithdrawalFee = (WithdrawalAmountBalance * 5) / 100;
    const NetPay = WithdrawalAmountBalance - WithdrawalFee;

    const RedirectToPreviousPage = () => {
        navigate(-1);
    };

    const RedirectToRechargeRecord = () => {
        navigate("/MyWithdrawals");
    };

    const getUserData = async () => {
        setLoading(true); // Show preloader while fetching data
        try {
            const UserName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_user_info',
                Username: UserName
            });

            if (response.data.success === true) {
                setUserData(response.data.result);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false); // Hide preloader once fetching is done
        }
    };

    const withdrawalAmount = async (e) => {
        e.preventDefault();
        
        let errorMessage = '';

        if (WithdrawalAmountBalance === "") {
            errorMessage += "Withdrawal Amount Balance can't be empty. ";
        } else if (UserData.upi === "") {
            errorMessage += "UPI can't be empty. ";
        } else if (loginPassword === "") {
            errorMessage += "Login Password can't be empty. ";
        }

        if (errorMessage) {
            toast.error(errorMessage.trim(), {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        const storedPassword = localStorage.getItem('Password');

        if (loginPassword !== storedPassword) {
            toast.error(`Password does not match.`, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        setLoading(true); // Show preloader while processing withdrawal
        try {
            const UserName = localStorage.getItem('Username');

            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'process_withdrawal',
                Username: UserName,
                WithdrawalFee: WithdrawalFee,
                NetPay: NetPay,
                WithdrawalAmount: WithdrawalAmountBalance,
                UPI: UserData.upi
            });
            
            if(response.data.success === true) {
                toast.success('Amount Has Been Withdrawn Successfully!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                
                setTimeout(() => {
                    navigate("/MyWithdrawals");
                }, 1500);
            } else {
                toast.error('Something Went Wrong. Please try again later.', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to process withdrawal. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false); // Hide preloader once processing is done
        }
    }

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <>
            {loading && <Preloader />} {/* Display preloader while loading */}
            <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 shadow-md">
                <div className="max-w-screen-xl mx-auto p-4 flex items-center justify-between relative">
                    <div className="flex items-center">
                        <button type="button" className="w-6 h-6">
                            <svg onClick={ RedirectToPreviousPage } className="w-full h-full text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex- text-center px-4">
                        <img src="./images/RealMoney.png" className="h-8" alt="Logo" />
                    </div>
                    <div className="flex items-center">
                        <svg onClick={RedirectToRechargeRecord} className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
            </nav>
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-2 mb-20">
                    <h1 className="text-3xl text-white text-center font-bold rounded-xl pb-4 pt-3" style={{ fontFamily: 'Varela Round' }}>
                        Withdrawal Amount
                    </h1>
                    <h1 className="text-3xl text-white text-center font-bold rounded-xl pb-4 pt-3" style={{ fontFamily: 'Varela Round' }}>
                        Balance:- { UserData.balance }
                    </h1>
                    <ul>
                        <li className="bg-[#343b6e] font-semibold p-3 rounded-lg shadow-lg border-b-4 border-green-500">
                            <div className="grid grid-cols-2 items-center gap-4">
                                <div className="flex items-center gap-2">
                                    <span className="text-red-500 bg-red-50 border border-red-400 text-sm font-medium px-2.5 py-0.5 rounded">
                                        Fee
                                    </span>
                                    <span className="text-white">{WithdrawalFee}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <span className="text-green-500 bg-green-50 border border-green-400 text-sm font-medium px-2.5 py-0.5 rounded">
                                        Net Pay
                                    </span>
                                    <span className="text-white">{NetPay}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <form className="mt-8" onSubmit={ withdrawalAmount }>
                        <div className="grid grid-cols-1 gap-2">
                            <div className="relative z-0 w-full mb-5 group">
                                <input 
                                    type="text" 
                                    onChange={(e) => setWithdrawalAmountBalance(e.target.value)}
                                    name="withdrawal_amount_balance" 
                                    id="withdrawal_amount_balance" 
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    autoComplete='Off'
                                />
                                <label 
                                    htmlFor="withdrawal_amount_balance" 
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    Withdrawal Amount/Balance
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    type="password"
                                    onChange={(e) => setLoginPassword(e.target.value)}
                                    name="login_password"
                                    id="login_password"
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    autoComplete='Off'
                                />
                                <label
                                    htmlFor="login_password"
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    Login Password
                                </label>
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2.5 px-4 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                            >
                                Withdraw Amount
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}