import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { HeaderForTabs } from "./HeaderForTabs";
import { API_URL } from './Config/config';
import axios from 'axios';
import { Preloader } from "./Preloader";

export function Profile() {
    const [UserData, setUserData] = useState({});
    const [selectedAccordion, setSelectedAccordion] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const accordions = [
        { id: 1, question: "Wallet", answer: ["Recharge", "Withdrawal", "History"] },
        { id: 2, question: "Account Security", answer: "Reset Password" },
        { id: 3, question: "App Download", answer: "Download Android Application" }
    ];

    const toggleAccordion = (id) => {
        setSelectedAccordion(selectedAccordion === id ? null : id);
    };

    const handleNavigation = (route) => {
        navigate(route);
    };

    const handleLogout = () => {
        localStorage.removeItem('loginStatus');
        localStorage.removeItem('RealMoneyloginDetails');
        localStorage.removeItem('Username');
        localStorage.removeItem('Password');
        navigate('/SignIn');
    };
    
    const getUserData = async () => {
        setLoading(true); // Show preloader while fetching data
        try {
            const UserName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_user_info',
                Username: UserName
            });

            if (response.data.success === true) {
                setUserData(response.data.result);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false); // Hide preloader once fetching is done
        }
    };

    useEffect(() => {
        getUserData();
        
        // const intervalId = setInterval(getUserData, 5000);
        // return () => clearInterval(intervalId);
    }, []);

    const formatNumberWithCommas = (number) => {
        if (number === undefined || number === null) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            <HeaderForTabs />
            {loading && <Preloader />} {/* Show preloader while loading */}
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-2 mb-20">
                    <div className="bg-[#343b6e] shadow-xl rounded-lg text-gray-900">
                        <div className="rounded-t-lg h-32 overflow-hidden">
                            <img className="object-cover object-top w-full" src='https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ' alt='Mountain' />
                        </div>
                        <div className="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
                            <img className="object-cover" src='./images/User.png' alt='User' />
                        </div>
                        <div className="text-center mt-2">
                            <h2 className="font-semibold text-white">
                                User :- {UserData.username || 'Loading...'}
                            </h2>
                            <p className="text-gray-300">
                                Id :- {UserData.usercode || 'Loading...'}
                            </p>
                            <h2 className="font-semibold text-white">
                                Balance :- {formatNumberWithCommas(UserData.balance) || 'Loading...'}
                            </h2>
                        </div>
                        <div className='container px-2'>
                            <hr className='mt-4' />
                            <ul className="shadow-box">
                                {accordions.map((item) => (
                                    <li key={item.id} className="relative border-b border-gray-200">
                                        <button type="button" className="w-full px-6 py-6 text-left" onClick={() => toggleAccordion(item.id)}>
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center">
                                                    {item.question === "Wallet" && (
                                                        <svg className="w-6 h-6 mr-2 text-gray-300" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z"/>
                                                            <path d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z"/>
                                                        </svg>
                                                    )}
                                                    {item.question === "Account Security" && (
                                                        <svg className="w-6 h-6 mr-2 text-gray-300" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" d="M11.484 2.17a.75.75 0 0 1 1.032 0 11.209 11.209 0 0 0 7.877 3.08.75.75 0 0 1 .722.515 12.74 12.74 0 0 1 .635 3.985c0 5.942-4.064 10.933-9.563 12.348a.749.749 0 0 1-.374 0C6.314 20.683 2.25 15.692 2.25 9.75c0-1.39.223-2.73.635-3.985a.75.75 0 0 1 .722-.516l.143.001c2.996 0 5.718-1.17 7.734-3.08ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75ZM12 15a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75H12Z" clip-rule="evenodd" />
                                                        </svg>
                                                    )}
                                                    {item.question === "App Download" && (
                                                        <svg className="w-6 h-6 mr-2 text-gray-300" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z" />
                                                            <path fillRule="evenodd" d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087ZM12 10.5a.75.75 0 0 1 .75.75v4.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72v-4.94a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
                                                        </svg>
                                                    )}
                                                    <span className="text-white">{item.question}</span>
                                                </div>
                                                <svg className={`w-5 h-5 text-gray-300 ${selectedAccordion === item.id ? 'transform rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </button>
                                        <div className="relative overflow-hidden transition-all max-h-0 duration-700" style={selectedAccordion === item.id ? { maxHeight: '1000px', opacity: '1' } : { maxHeight: '0', opacity: '0' }}>
                                            <div className=''>
                                                <hr className='my-2' />
                                                {Array.isArray(item.answer) ? (
                                                    item.answer.map((paragraph, index) => (
                                                        <React.Fragment key={index}>
                                                            <p className="my-3 ml-3 cursor-pointer text-white" onClick={() => handleNavigation(`/${paragraph}`)}>{paragraph}</p>
                                                            {index < item.answer.length - 1 && <hr className='my-2' />}
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    <p className="my-3 ml-3 cursor-pointer text-white" onClick={() => handleNavigation(`/${item.answer.replace(/\s+/g, '')}`)}>{item.answer}</p>
                                                )}
                                                <hr className='my-2' />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                <li className="relative border-b border-gray-200">
                                    <button type="button" className="w-full px-6 py-6 text-left" onClick={() => handleNavigation(`/Promotion`)}>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <svg className="w-6 h-6 mr-2 text-gray-300" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.375 3a1.875 1.875 0 0 0 0 3.75h1.875v4.5H3.375A1.875 1.875 0 0 1 1.5 9.375v-.75c0-1.036.84-1.875 1.875-1.875h3.193A3.375 3.375 0 0 1 12 2.753a3.375 3.375 0 0 1 5.432 3.997h3.943c1.035 0 1.875.84 1.875 1.875v.75c0 1.036-.84 1.875-1.875 1.875H12.75v-4.5h1.875a1.875 1.875 0 1 0-1.875-1.875V6.75h-1.5V4.875C11.25 3.839 10.41 3 9.375 3ZM11.25 12.75H3v6.75a2.25 2.25 0 0 0 2.25 2.25h6v-9ZM12.75 12.75v9h6.75a2.25 2.25 0 0 0 2.25-2.25v-6.75h-9Z" />
                                                </svg>
                                                <span className="text-white">Promotion</span>
                                            </div>
                                            <svg className="w-5 h-5 text-gray-300 rotate-270" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" />
                                            </svg>
                                        </div>
                                    </button>
                                </li>
                                <li className="relative border-b border-gray-200">
                                    <button type="button" className="w-full px-6 py-6 text-left" onClick={() => handleNavigation(`/AddUPI`)}>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <svg className="w-6 h-6 mr-2 text-gray-300" fill="currentColor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clip-rule="evenodd" />
                                                </svg>
                                                <span className="text-white">Add UPI</span>
                                            </div>
                                            <svg className="w-5 h-5 text-gray-300 rotate-270" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" />
                                            </svg>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <button
                onClick={handleLogout}
                className="fixed bottom-4 mb-14 right-4 bg-blue-500 text-white p-2 rounded-full shadow-xl hover:bg-blue-600 focus:outline-none"
            >
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V6a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                </svg>
            </button>
        </>
    );
}