import axios from "axios";
import { Header } from "./Header";
import { API_URL } from './Config/config';
import { useEffect, useState } from "react";
import { Preloader } from "./Preloader";

export function RechargeRecord() {
    const [UserRechargeRecordData, setUserRechargeRecordData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getUserRechargeRecordData = async () => {
        setLoading(true);
        try {
            const UserName = localStorage.getItem('Username');

            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_recharge_record',
                Username: UserName
            });
            
            if(response.data.success === true) {
                setUserRechargeRecordData(response.data.result);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserRechargeRecordData();

        // const intervalId = setInterval(getUserRechargeRecordData, 5000);
        // return () => clearInterval(intervalId);
    }, []);

    const getStatusClassesForText = (status) => {
        switch (status) {
            case 'success':
                return 'text-green-500 bg-green-50 border border-green-400';
            case 'Failed':
                return 'text-red-500 bg-red-50 border border-red-400';
            case 'unpaid':
                return 'text-yellow-500 bg-yellow-50 border border-yellow-400';
            default:
                return 'text-blue-500 bg-blue-50 border border-blue-400';
        }
    };

    const getStatusClassesForBorder = (status) => {
        switch (status) {
            case 'success':
                return 'border-green-500';
            case 'Failed':
                return 'border-red-500';
            case 'unpaid':
                return 'border-yellow-500';
            default:
                return 'text-blue-500 border-blue-500';
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const formatNumberWithCommas = (number) => {
        if (number === undefined || number === null) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            <Header />
            {loading && <Preloader />}
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-1.5 mb-20">
                    <div className="">
                        <ul>
                            {UserRechargeRecordData.length > 0 ? (
                                UserRechargeRecordData.map((item, index) => (
                                    <li key={index} className={`bg-[#343b6e] font-semibold py-3 px-2 mt-5 rounded-lg shadow-lg border-b-4 ${getStatusClassesForBorder(item.status)}`}>
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-9 text-white">
                                                ₹ {formatNumberWithCommas(item.recharge)}
                                            </div>
                                            <div className="col-span-3 text-right">
                                                <span className={`${getStatusClassesForText(item.status)} text-sm font-medium me-2 px-2.5 py-0.5 rounded`}>
                                                    {capitalizeFirstLetter(item.status)}
                                                </span>
                                            </div>
                                            <div className="col-span-9 mt-2 text-white">
                                                {item.rand}
                                            </div>
                                            <div className="col-span-3 text-right mt-2 text-white">
                                                Ek Pay
                                            </div>
                                            <div className="col-span-12 mt-2 text-white">
                                                {item.created_at}
                                            </div>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                !loading && (
                                    <li className="bg-[#343b6e] py-3 px-2 mt-5 rounded-lg shadow-lg border-b-2 border-red-500">
                                        <div className="col-span-12 text-white">
                                            No data available
                                        </div>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}