import { Routes, Route } from 'react-router-dom';
import { Home } from './Home';
import { Promotion } from './Promotion';
import { Profile } from './Profile';
import { Recharge } from './Recharge';
import { Withdrawal } from './Withdrawal';
import { History } from './History';
import { SignUp } from './SignUp';
import { SignIn } from './SignIn';
import { ProtectedComponent } from './ProtectedComponent';
import { RechargeRecord } from './RechargeRecord';
import { Betting } from './Betting';
import { AddUPI } from './AddUPI';
import { MyUPI } from './MyUPI';
import { MyWithdrawals } from './MyWithdrawals';
import { ResetPassword } from './ResetPassword';
// import SlotMachine from './SlotMachine';

function App() {
  return (
    <Routes>
      <Route path="/" element={ <Home /> } />
      <Route path="/Promotion" element={ <ProtectedComponent RedirectComponent={Promotion} /> } />
      <Route path="/Profile" element={ <ProtectedComponent RedirectComponent={ Profile } /> } />
      <Route path="/Recharge" element={<ProtectedComponent RedirectComponent={ Recharge } />} />
      <Route path="/Withdrawal" element={<ProtectedComponent RedirectComponent={ Withdrawal } />} />
      <Route path="/History" element={<ProtectedComponent RedirectComponent={ History } />} />
      <Route path="/RechargeRecord" element={<ProtectedComponent RedirectComponent={ RechargeRecord } />} />
      <Route path="/Betting" element={<ProtectedComponent RedirectComponent={ Betting } />} />
      <Route path="/AddUPI" element={<ProtectedComponent RedirectComponent={ AddUPI } />} />
      <Route path="/MyUPI" element={<ProtectedComponent RedirectComponent={ MyUPI } />} />
      <Route path="/MyWithdrawals" element={<ProtectedComponent RedirectComponent={ MyWithdrawals } />} />
      <Route path="/ResetPassword" element={<ProtectedComponent RedirectComponent={ ResetPassword } />} />
      {/* <Route path="/SlotMachine" element={<ProtectedComponent RedirectComponent={ SlotMachine } />} /> */}
      <Route path="/SignUp" element={<SignUp />} />
      <Route path="/SignIn" element={<SignIn />} />
    </Routes>
  );
}

export default App;