// import { Header } from "./Header";
// import { toast, ToastContainer } from "react-toastify";
// import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined, KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect, useRef, useState } from "react";
import { API_URL } from "./Config/config";
import './SlotMachine.css';
import axios from "axios";
import ReactAudioPlayer from "react-audio-player";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Preloader } from "./Preloader";

const tikTokSound = "./images/countdown.m4a";

export function Betting() {
    // const [betAmount, setBetAmount] = useState('');
    // const [showBetModal, setShowBetModal] = useState(false);
    // const [activeTab, setActiveTab] = useState('my-record');
    // const [accordionOpen, setAccordionOpen] = useState(false);
    // const [showWinner, setWinner] = useState();
    // const [AllRecordpagination, setAllRecordpagination] = useState(1);
    // const [MyRecord, setMyRecord] = useState(null);
    // const [AllRecord, setAllRecord] = useState(null);

    const [GameCategory, setGameCategory] = useState(null);
    const [PeriodId, setPeriodId] = useState(null);
    const [timeLeft, setTimeLeft] = useState(30);
    const [playSound, setPlaySound] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showBetStatus, setBetStatus] = useState('');
    const [pagination, setPagination] = useState(1);
    const [tableImage, setTableImage] = useState('');
    const [totalBetAmount, setTotalBetAmount] = useState(0);
    const [showTotalBetAmount, setShowTotalBetAmount] = useState(true);
    const [showTableId, setTableId] = useState(null);
    const [showCurrentTableRecord, setCurrentTableRecord] = useState(null);
    const [targetBetAmount, setTargetBetAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [audio] = useState(new Audio('./images/coinsSound.mp3'));
    const location = useLocation();
    const navigate = useNavigate();
    const { game_id } = location.state || {};
    
    const [winnerOrder1, setWinnerOrder1] = useState(null);
    const [rolling, setRolling] = useState(false);
    const [showWinner, setShowWinner] = useState(null);
    const [winnerOrders, setWinnerOrders] = useState(["You", "Other"]);

    const slotRefs = useRef([]);
    const rollDuration = 2000;
    let intervalId;
    let stopTimeoutId;
    
    const getWinner = async () => {
        setLoading(true);
        
        try {
            const UserName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'GetWinnerOrder',
                Username: UserName,
                GameId: game_id,
                TableId: showTableId,
                PeriodId: PeriodId
            });
            
            if (response.data.success === true) {
                const { winner, winnerUser } = response.data;
                setShowWinner(winnerUser);

                // console.log("Winner from API response:", winner);
                // console.log("WinnerUser from API response:", winnerUser);
                
                setWinnerOrders([winnerUser, "You", "Other"]);

                return winnerUser;
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };
    
    const roll = async () => {
        if (rolling) return;

        const winner = await getWinner();
        if (!winner) return;

        setRolling(true);
        let startTime = Date.now();
        let stopPosition = null;

        intervalId = setInterval(() => {
            slotRefs.current.forEach((slotRef, i) => {
                if (slotRef) {
                    const selected = triggerSlotRotation(slotRef);
                    if (i === 0) setWinnerOrder1(selected);
                }
            });
        }, 50);

        stopTimeoutId = setTimeout(() => {
            clearInterval(intervalId);
            setRolling(false);
            stopAtWinner(winner);
        }, rollDuration);
    };

    const stopAtWinner = (winner) => {
        slotRefs.current.forEach((slotRef) => {
            if (slotRef) {
                const options = Array.from(slotRef.children);
                const optionHeight = options[0].offsetHeight;

                options.forEach((option, index) => {
                    if (option.textContent === winner) {
                        const position = -index * optionHeight + (slotRef.offsetHeight / 2) - (optionHeight / 2);
                        slotRef.style.top = `${position}px`;
                    }
                });
            }
        });
    };

    const triggerSlotRotation = (ref) => {
        function setTop(top) {
            ref.style.top = `${top}px`;
        }

        let options = ref.children;
        if (options.length === 0) return winnerOrders[0];

        let optionCount = options.length;
        let topOffset = Math.floor(Math.random() * optionCount) * 50;
        setTop(-topOffset);
        return winnerOrders[topOffset / 50 % winnerOrders.length];
    };

    const updatePeriodId = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_update_period_id'
            });

            if(response.data.success === true) {
                setPeriodId(response.data.Updated_Period_Id);
            } else {
                console.error('Error updating data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    const getPeriodId = async () => {
        try {
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_fetch_periodId'
            });
            
            if(response.data.success === true) {
                setPeriodId(response.data.Period_Id);
                setTimeLeft(30);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };
    
    const getGameCategory = async () => {
        try {
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_fetch_game_category_by_id',
                GameId: game_id
            });
            
            if (response.data.success === true) {
                const gameDetails = response.data.result[0];
                setGameCategory(gameDetails);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    const getMyRecord = async () => {
        try {
            const UserName = localStorage.getItem('Username');
            
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_my_bet_record',
                Page: pagination,
                Username: UserName
            });
            
            if (response.data.success === true) {
                // const myRecordDetails = response.data.result;
                const userLastBetRecord = response.data.userLastBetRecord;
                
                // setMyRecord(myRecordDetails);
                
                if (userLastBetRecord && userLastBetRecord.table_id) {
                    setTableId(userLastBetRecord.table_id);
                } else {
                    setTableId(null);
                }

                // console.log('MyBetRecord', MyRecord);
                // console.log('userLastBetRecord', showTableId);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };
    
    // const getAllRecord = async () => {
    //     try {
    //         const response = await axios.post(`${API_URL}/Index.php`, {
    //             aksi: 'proses_all_bet_record',
    //             GameId: game_id,
    //             Page: AllRecordpagination
    //         });
            
    //         if(response.data.success === true)
    //         {
    //             const allBetRecordDetails = response.data.result;
    //             setAllRecord(allBetRecordDetails);
    //         }
    //         else
    //         {
    //             console.error('Error fetching data:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error("Error", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    
    const getTables = async () => {
        try {
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'GetTable',
                GameId: game_id
            });
            
            if (response.data.success) {
                const Tables = response.data.result;
                const totalAmount = parseInt(response.data.TotalBetAmount, 10);

                setTableImage(Tables);
                setTargetBetAmount(totalAmount);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    const declaredResult = async () => {
        try {
            const UserName = localStorage.getItem('Username');
            
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'Declared_Result',
                GameId: game_id,
                Username: UserName
            });
            
            if (response.data.success === true)
            {
                // console.log(response.data);
                // console.log('WinnerOrder', response.data.winner);
                setBetStatus(response.data.userBetRecord.BetRecord.status === "Success");
                
                // if (response.data.userBetRecord.BetRecord.status !== null && response.data.userBetRecord.BetRecord.status !== "" && response.data.userBetRecord.BetRecord.status !== undefined) {
                //     setShowModal(true);
                // }
                // else
                // {
                //     setShowModal(false);
                // }
            }
            else if (response.data.success === false)
            {
                // console.log('No Bets Found For This');
            }
            else
            {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };
    
    const getCurrentTableRecord = async () => {
        try {
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_same_table_player',
                TableId: showTableId
            });
            
            if(response.data.success === true)
            {
                const currentTableBetRecordDetails = response.data.result;
                setCurrentTableRecord(currentTableBetRecordDetails);

                // console.log('CurrentTable1', showCurrentTableRecord);
                // console.log('CurrentTable2', currentTableBetRecordDetails);
            }
            else
            {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };
    
    // useEffect(() => {
    //     getAllRecord();

    //     const intervalId = setInterval(() => {
    //         getAllRecord();
    //     }, 500);
        
    //     return () => clearInterval(intervalId);
    // }, []);

    useEffect(() => {
        if (targetBetAmount > 0) {
            let count = 0;
            const increment = Math.ceil(targetBetAmount / 100);
            const duration = 2000;
            const intervalTime = 30;
            const totalSteps = duration / intervalTime;

            audio.play();

            const timer = setInterval(() => {
                if (count < targetBetAmount) {
                    count += increment;
                    if (count > targetBetAmount) count = targetBetAmount;
                    setTotalBetAmount(count);
                } else {
                    clearInterval(timer);
                    audio.pause();
                    
                    audio.currentTime = 0;
                }
            }, intervalTime);

            return () => {
                clearInterval(timer);
                audio.pause();
                audio.currentTime = 0;
                setTotalBetAmount(0);
            };
        }
    }, [targetBetAmount, audio]);
    
    const startCounting = () => {
        if(targetBetAmount > 0)
        {
            audio.play();
            setTotalBetAmount(0);
        }
    };
    
    useEffect(() => {
        startCounting();
    }, []);

    useEffect(() => {
        getTables();

        const intervalId = setInterval(() => {
            getTables();
        }, 100);
        
        return () => clearInterval(intervalId);
    }, []);
    
    useEffect(() => {
        const loadData = async () => {
            await Promise.all([
                getPeriodId(),
                getGameCategory(),
                getMyRecord(),
                // getAllRecord(),
                getTables()
            ]);
            setIsContentLoaded(true);
        };
        
        loadData();

        const periodIntervalId = setInterval(async () => {
            updatePeriodId();
            navigate('/');
            getMyRecord();
            getPeriodId();
        }, 30000);
        
        return () => clearInterval(periodIntervalId);
    // }, [game_id, pagination]);
    }, [game_id]);

    // useEffect(() => {
    //     getPeriodId();
    //     getGameCategory();
    //     getMyRecord();
    //     // declaredResult();
    //     // getWinner();
        
    //     const periodIntervalId = setInterval(async () => {
    //         updatePeriodId();
    //         navigate('/');
    //         getMyRecord();
    //         getPeriodId();
    //         // declaredResult();
    //         // getWinner();
    //     }, 30000);
        
    //     return () => clearInterval(periodIntervalId);
    // }, [game_id, pagination]);
    
    // const getWinner = async () => {
    //     setLoading(true);
    
    //     try {
    //         const response = await axios.post(`${API_URL}/Index.php`, {
    //             aksi: 'GetWinnerOrder',
    //             GameId: game_id,
    //             TableId: showTableId,
    //             PeriodId: PeriodId
    //         });
            
    //         if (response.data.success === true) {
    //             const winner = response.data.winner; // Get the winner from the response
    //             setWinner(winner);
    //             console.log("Winner from API response:", winner); // Log winner from response
    //         } else {
    //             console.error('Error fetching data:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error("Error", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimeLeft((prevTime) => {
                if(prevTime > 1) {
                    if(timeLeft === 30 || timeLeft <= 30)
                    {
                        getMyRecord();
                        getCurrentTableRecord();
                    }
                    
                    if (timeLeft === 10) {
                        ConfirmDummyBet();
                    }
                    
                    if (timeLeft === 7) {
                        declaredResult();
                    }
                    
                    if (timeLeft === 6) {
                        setShowTotalBetAmount(false);
                        roll();
                        if (showBetStatus) {
                            setShowModal(false);
                        } else {
                            setShowModal(false);
                        }
                    }
                    
                    if (timeLeft <= 12) {
                        setPlaySound(true);
                    } else {
                        setPlaySound(false);
                    }

                    // if (timeLeft === 1) {
                    //     setTableImage('');
                    //     setTargetBetAmount(0);
                    //     setTotalBetAmount(0);
                    //     getMyRecord();
                    //     setShowModal(false);
                         
                    //     RedirectToHomePage('/');
                    // }
                    
                    return prevTime - 1;
                }
                else if (prevTime === 1) {
                    setTableImage('');
                    setTargetBetAmount(0);
                    setTotalBetAmount(0);
                    getMyRecord();
                    setShowModal(false);
                    
                    return 30;
                } else {
                    return 0;
                }
            });
        }, 1000);
        
        return () => clearInterval(countdown);
    }, [showBetStatus, timeLeft]);
    
    const ConfirmDummyBet = async () => {
        try {
            const loginDetails = localStorage.getItem('RealMoneyloginDetails');
            if(!loginDetails) {
                // toast.error('Login details not found!', {
                //     position: "top-center",
                //     autoClose: 1500,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: true,
                //     progress: undefined,
                // });
    
                navigate('./SignIn');
                return;
            }
            
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_dummy_betting',
                UserId: "1",
                Username: "8218832132",
                GameId: game_id,
                PeriodId: PeriodId,
                BettingAmount: GameCategory.bet_amount,
            });
            
            if (response.data.success === true) {
                // toast.success('Bet Has Been Confirmed Successfully...!', {
                //     position: "top-center",
                //     autoClose: 1500,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: true,
                //     progress: undefined,
                // });
                
                getMyRecord();
                startCounting();
            } else if (response.data.result === "Insufficient Balance") {
                // toast.error('Insufficient Balance...!', {
                //     position: "top-center",
                //     autoClose: 1500,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: true,
                //     progress: undefined,
                // });
            } else {
                // toast.error('Something Went Wrong...!', {
                //     position: "top-center",
                //     autoClose: 1500,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: true,
                //     progress: undefined,
                // });
            }
        } catch (error) {
            console.error("Error", error);
            // toast.error('An error occurred. Please try again later.', {
            //     position: "top-center",
            //     autoClose: 1500,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: false,
            //     draggable: true,
            //     progress: undefined,
            // });
        } finally {
            setLoading(false); // End loading
        }
    };

    // const toggleBetModal = () => {
    //     setShowBetModal(!showBetModal);
    //     setBetAmount('');
    // };

    // const toggleModal = () => {
    //     setShowModal(!showModal);
    // };
    
    // const ConfirmBet = async (betAmount) => {
    //     if (betAmount === null || betAmount === undefined || betAmount < 10) {
    //         toast.error('Enter a minimum bet amount of 10...!', {
    //             position: "top-center",
    //             autoClose: 1500,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: false,
    //             draggable: true,
    //             progress: undefined,
    //         });
            
    //         return;
    //     }
    
    //     try {
    //         const loginDetails = localStorage.getItem('RealMoneyloginDetails');
    //         if(!loginDetails) {
    //             toast.error('Login details not found!', {
    //                 position: "top-center",
    //                 autoClose: 1500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: false,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //             navigate('./SignIn');
    //             return;
    //         }
    
    //         const parsedDetails = JSON.parse(loginDetails);
            
    //         const UserId = parsedDetails?.result?.id;
    //         const UserName = parsedDetails?.result?.Username;
    
    //         const response = await axios.post(`${API_URL}/Index.php`, {
    //             aksi: 'proses_betting',
    //             UserId: UserId,
    //             Username: UserName,
    //             GameId: game_id,
    //             PeriodId: PeriodId,
    //             BettingAmount: betAmount,
    //         });
            
    //         if (response.data.success === true) {
    //             toast.success('Bet Has Been Confirmed Successfully...!', {
    //                 position: "top-center",
    //                 autoClose: 1500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: false,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //             getMyRecord();
    //             startCounting();
    //         } else if (response.data.result === "Insufficient Balance") {
    //             toast.error('Insufficient Balance...!', {
    //                 position: "top-center",
    //                 autoClose: 1500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: false,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         } else if(response.data.result === "Minimum members limit reached for this game") {
    //             toast.error('Members limit reached for this period id...!', {
    //                 position: "top-center",
    //                 autoClose: 1500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: false,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         } else if(response.data.result === "You have already placed a bet for this period") {
    //             toast.error('You have already placed a bet for this period...!', {
    //                 position: "top-center",
    //                 autoClose: 1500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: false,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         } else {
    //             toast.error('Something Went Wrong...!', {
    //                 position: "top-center",
    //                 autoClose: 1500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: false,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error", error);
    //         toast.error('An error occurred. Please try again later.', {
    //             position: "top-center",
    //             autoClose: 1500,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: false,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }
    //     // setShowBetModal(!showBetModal);
    //     // setBetAmount('');
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     console.log("Betting Amount:-", betAmount);
    //     setBetAmount('');
    // };

    /* const toggleAccordion = (index) => {
        setAccordionOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const getStatusClassesForSpanText = (status) => {
        switch (status) {
            case 'Success':
                return 'text-green-500 bg-green-50 border border-green-400';
            case 'Failed':
                return 'text-red-500 bg-red-50 border border-red-400';
            case 'Wait':
                return 'text-yellow-500 bg-yellow-50 border border-yellow-400';
            default:
                return 'text-gray-500';
        }
    };

    const getStatusClassesForBorder = (status) => {
        switch (status) {
            case 'Success':
                return 'border-green-500';
            case 'Failed':
                return 'border-red-500';
            case 'Wait':
                return 'border-yellow-500';
            default:
                return 'text-gray-500 border-gray-500';
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }; */

    const closeModal = () => {
        setShowModal(false);
        navigate('/');
        updatePeriodId();
    };
    
    return (
        <>
            { !isContentLoaded ? (
                    <Preloader />
                ) : (
                    <>
                    {/* <Header />
                    <ToastContainer position="top-center" autoClose={3000} /> */}
                    {/* <div 
                        className="max-w-md mx-auto p-2" 
                        style={{
                            backgroundImage: 'url("./images/new_red_bg.png")',
                            backgroundSize: 'contain'
                        }}
                    > */}
                    <div 
                        className="relative min-h-screen max-w-md mx-auto p-2"
                        style={{
                            backgroundImage: 'url("./images/new_red_bg.png")',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <div className="mb-3">
                            <h1 className="font-bold text-xl text-white items-center justify-center text-center">
                                {GameCategory ? GameCategory.game_title : "Loading..."}
                            </h1>
                        </div>

                        <hr className="mb-3 border border-blue-500" />

                        <div className="flex justify-end mt-5 items-center h-full">
                            <div className="text-center transform rotate-90">
                                <span className="text-md font-bold text-white">
                                    Period Id
                                </span>
                                <h1 className="text-xl font-extrabold text-white">
                                    { isLoading ? 'Loading...' : PeriodId }
                                </h1>
                            </div>
                        </div>
                        
                        <div
                            className="mt-[-50px] w-full min-h-[500px] bg-center bg-no-repeat relative"
                            style={{
                                backgroundImage: `url('${tableImage || "https://kheladda.co.in/trova/src/api/RealMoneyAPI/1.png"}')`,
                                backgroundSize: 'contain',
                            }}
                        >
                            {/* {showCurrentTableRecord && showCurrentTableRecord.length > 0 ? (
                                showCurrentTableRecord.map((item, index) => (
                                    <div 
                                        key={index} 
                                        className={`absolute left-1/2 transform -translate-x-1/2 ${index === 0 ? 'top-0 mt-4' : 'bottom-0 mb-4'}`}
                                    >
                                        <div className="text-center text-white text-xl font-bold bg-black bg-opacity-50 p-2 rounded-full">
                                            {item.user_id || "No Upper Player"}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center">No records found.</p>
                            )} */}
                            <div className="absolute inset-0 flex items-center justify-center">
                                {showTotalBetAmount === true ? (
                                    <div className="text-center text-white text-2xl font-bold rotate-90 mr-16 mt-10 bg-black bg-opacity-50 p-2 rounded-full">
                                        {totalBetAmount}
                                    </div>
                                    ) : (
                                        <div className="SlotMachineee rotate-90 mr-20 mt-10">
                                            <div className="slottt">
                                                <section className='sectionnn'>
                                                    {winnerOrders.map((_, i) => (
                                                        <div
                                                            className="containerr"
                                                            ref={(el) => slotRefs.current[i] = el}
                                                            key={i}
                                                        >
                                                            {winnerOrders.map(winnerOrder => (
                                                                <div
                                                                    key={winnerOrder}
                                                                    className={`${!rolling ? (winnerOrder === showWinner ? 'winner' : 'non-winner') : ''} ${rolling ? 'stopped' : ''}`}
                                                                >
                                                                    <span>{winnerOrder}</span>
                                                                </div>                  
                                                            ))}
                                                        </div>
                                                    ))}
                                                </section>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        
                        <div className="relative h-full w-full mt-10">
                            <div className="absolute bottom-0 right-0 text-center transform rotate-90">
                                <div className="border-4 border-blue-500 border-dashed rounded-xl py-2 px-4">
                                    <span className="text-md font-bold text-green-400">
                                        Time Left
                                    </span>
                                    <h1 className="text-xl font-extrabold text-white">
                                        { timeLeft }
                                    </h1>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="mt-5">
                            <div className="grid grid-cols-3 gap-1">
                                { [10, 20, 50, 100, 500, 1000].map(amount => (
                                    <div key={amount} className="">
                                        <button 
                                            onClick={() => ConfirmBet(amount)}
                                            disabled={timeLeft <= 10}
                                            className={`w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group ${timeLeft <= 10 ? 'cursor-not-allowed opacity-50' : ''}`}
                                        >
                                            <img src={`./images/chips/${amount}.png`} alt={`${amount} Bet Amount`} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div> */}
                        
                        {/* <div className="my-5">
                            <button 
                                onClick={toggleBetModal}
                                disabled={timeLeft <= 10}
                                className={`w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800 ${timeLeft <= 10 ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                <span className="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                    Bet
                                </span>
                            </button>
                        </div> */}
                        
                        {/* <div className="grid grid-cols-2 gap-4 mb-2 mt-16">
                            <button
                                onClick={() => setActiveTab('my-record')}
                                className={`p-4 w-full border-b-2 rounded-t-lg ${activeTab === 'my-record' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-white text-white hover:border-gray-300 dark:hover:text-white'}`}
                            >
                                My Record
                            </button>
                            <button
                                onClick={() => setActiveTab('all-record')}
                                className={`p-4 w-full border-b-2 rounded-t-lg ${activeTab === 'all-record' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-white text-white hover:border-gray-300 dark:hover:text-white'}`}
                            >
                                All Record
                            </button>
                        </div> */}
                        
                        {/* <div>
                            {activeTab === 'my-record' && (
                                <div className="bg-gray-50 p-2 rounded-lg">
                                    {MyRecord && MyRecord.length > 0 ? (
                                        MyRecord.map((item, index) => (
                                            <div key={index} className="border rounded-lg mb-2">
                                                <button
                                                    onClick={() => toggleAccordion(item.id)}
                                                    className={`w-full flex justify-between items-center p-4 bg-gray-200 rounded-t-lg focus:outline-none border-b-4 ${getStatusClassesForBorder(item.status)}`}
                                                >
                                                    <div className="flex items-center space-x-2">
                                                        <span className="font-semibold">{item.period_id}</span>
                                                        <span className={`${getStatusClassesForSpanText(item.status)} text-sm font-medium px-2.5 py-0.5 rounded`}>
                                                            {capitalizeFirstLetter(item.status)}
                                                        </span>
                                                    </div>
                                                    <div className="ml-auto">
                                                        {accordionOpen[item.id] ? <KeyboardArrowUpOutlined className="h-4 w-4" /> : <KeyboardArrowDownOutlined className="h-4 w-4" />}
                                                    </div>
                                                </button>
                                                <div
                                                    className={`p-3 border-t ${accordionOpen[item.id] ? 'block' : 'hidden'}`}
                                                    style={{
                                                        transition: 'max-height 0.2s ease-in-out'
                                                    }}
                                                >
                                                    <div className="grid grid-cols-2 gap-3">
                                                        <h1 className="text-gray-700 font-bold text-md">
                                                            Game Name
                                                        </h1>
                                                        <h1 className="text-blue-700 font-semibold text-md">
                                                            {item.game_title}
                                                        </h1>
                                                        <hr className="col-span-2" />
                                                        <h1 className="text-gray-700 font-bold text-md">
                                                            Period Id
                                                        </h1>
                                                        <h1 className="text-blue-700 font-semibold text-md">
                                                            {item.period_id}
                                                        </h1>
                                                        <hr className="col-span-2" />
                                                        <h1 className="text-gray-700 font-bold text-md">
                                                            Bet Amount
                                                        </h1>
                                                        <h1 className="text-blue-700 font-semibold text-md">
                                                            {item.betting_amount}
                                                        </h1>
                                                        <hr className="col-span-2" />
                                                        <h1 className="text-gray-700 font-bold text-md">
                                                            Status
                                                        </h1>
                                                        <div className="flex items-center space-x-2">
                                                            <span className={`${getStatusClassesForSpanText(item.status)} text-sm font-medium px-2.5 py-0.5 rounded`}>
                                                                {capitalizeFirstLetter(item.status)}
                                                            </span>
                                                        </div>
                                                        <hr className="col-span-2" />
                                                        <h1 className="text-gray-700 font-bold text-md">
                                                            Amount
                                                        </h1>
                                                        <h1 className={`font-semibold text-md ${item.amount === '0' ? 'text-red-500' : 'text-green-600'}`}>
                                                            ₹{item.amount === '0' ? item.amount : item.amount}
                                                        </h1>
                                                        <hr className="col-span-2" />
                                                        <h1 className="text-gray-700 font-bold text-md">
                                                            Bet Time
                                                        </h1>
                                                        <h1 className="text-blue-700 font-semibold text-md">
                                                            {item.date_time}
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-center">No records found.</p>
                                    )}
                                    <div className="flex justify-between items-center p-2">
                                        <button
                                            className={`flex items-center space-x-2 ${pagination === 1 ? 'text-gray-400 cursor-not-allowed' : 'text-blue-500'}`}
                                            onClick={() => setPagination(pagination - 1)}
                                            disabled={pagination === 1}
                                        >
                                            <KeyboardArrowLeftOutlined /> Previous
                                        </button>
                                        <button
                                            className={`flex items-center space-x-2 ${!MyRecord || MyRecord.length === 0 ? 'text-gray-400 cursor-not-allowed' : 'text-blue-500'}`}
                                            onClick={() => setPagination(pagination + 1)}
                                            disabled={!MyRecord || MyRecord.length === 0}
                                        >
                                            Next <KeyboardArrowRightOutlined />
                                        </button>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'all-record' && (
                                <div>
                                    <div className="text-center items-center justify-center">
                                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border border-slate-500">
                                                <thead className="text-xs text-white uppercase bg-black">
                                                    <tr>
                                                        <th scope="col" className="px-3 py-1.5 border border-slate-500">
                                                            User Id
                                                        </th>
                                                        <th scope="col" className="px-3 py-1.5 border border-slate-500">
                                                            Bet Amount
                                                        </th>
                                                        <th scope="col" className="px-3 py-1.5 border border-slate-500">
                                                            Bet Time
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        AllRecord.length > 0 ? (
                                                            AllRecord.map((item, index) => (
                                                                <tr key={index} className="bg-white border-b">
                                                                    <th scope="row" className="px-3 py-2 font-medium text-gray-900 whitespace-nowrap border border-slate-500">
                                                                        <AccountCircleIcon /> {item.user_unique_id}
                                                                    </th>
                                                                    <td className="px-3 py-2 border border-slate-500">
                                                                        {item.betting_amount}
                                                                    </td>
                                                                    <td className="px-3 py-2 border border-slate-500">
                                                                        {item.date_time}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="3" className="px-3 py-2 text-center border border-slate-500">
                                                                    No record available
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="flex justify-between items-center p-2">
                                                <button
                                                    className={`flex items-center space-x-2 ${AllRecordpagination === 1 ? 'text-gray-400 cursor-not-allowed' : 'text-blue-500'}`}
                                                    onClick={() => setAllRecordpagination(AllRecordpagination - 1)}
                                                    disabled={AllRecordpagination === 1}
                                                >
                                                    <KeyboardArrowLeftOutlined /> Previous
                                                </button>
                                                <button
                                                    className={`flex items-center space-x-2 ${!AllRecord || AllRecord.length === 0 ? 'text-gray-400 cursor-not-allowed' : 'text-blue-500'}`}
                                                    onClick={() => setAllRecordpagination(AllRecordpagination + 1)}
                                                    disabled={!AllRecord || AllRecord.length === 0}
                                                >
                                                    Next <KeyboardArrowRightOutlined />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div> */}
                    </div>
                    {playSound && <ReactAudioPlayer src={tikTokSound} autoPlay />}
                    
                    {showModal && (
                        <>
                            <div className="fixed inset-0 z-50 overflow-y-auto">
                                <div className="flex items-center justify-center min-h-screen pt-4 px-2 pb-20 text-center sm:block sm:p-0">
                                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                    </div>
                                    
                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                    <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-full w-full max-w-full">
                                        <div className="bg-white dark:bg-gray-800 px-3 pt-5 pb-2 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-center sm:justify-center">
                                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:flex sm:items-center">
                                                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                                                        {showBetStatus === "Success" ? "Congratulations!" : "Sorry, Try Again!"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                                                {showBetStatus === "Success" ? "You have won the bet. Well done!" : "Unfortunately, you have lost the bet. Better luck next time."}
                                            </p>
                                            <hr className="my-5 border border-blue-500" />
                                            <div className="grid grid-cols-1 gap-4">
                                                <div className="bg-gray-50 dark:bg-gray-700 pb-3">
                                                    <button
                                                        type="button"
                                                        onClick={ closeModal }
                                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="fixed inset-0 z-50 overflow-y-auto">
                                <div className="flex items-center justify-center min-h-screen pt-4 px-2 pb-20 text-center sm:block sm:p-0">
                                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                    </div>

                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                    <div className="inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle max-w-md">
                                        <div className="px-3 pt-5 pb-2 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-center sm:justify-center">
                                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:flex sm:items-center">
                                                    <img src={showBetStatus === "Success" ? "https://i.pinimg.com/originals/4b/7f/10/4b7f1092b343370323c6d160e78faaf8.gif" : "https://i.pinimg.com/originals/e4/d2/c1/e4d2c1d0da356797359acd9270bcdd77.gif"} alt="Status" className="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </>
                    )}
                    {/* {showBetModal && (
                        <div className="fixed inset-0 z-50 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-screen pt-4 px-2 pb-20 text-center sm:block sm:p-0">
                                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                </div>
                                
                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        
                                <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white dark:bg-gray-800 px-3 pt-5 pb-2 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-center sm:justify-center">
                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:flex sm:items-center">
                                                <h3 className="text-lg font-medium text-gray-900 dark:text-white">Bet</h3>
                                            </div>
                                        </div>
                                        <hr className="mb-4 mt-2 border border-blue-500" />
                                        <form className="mt-4" onSubmit={handleSubmit}>
                                            <div className="grid grid-cols-1 gap-2">
                                                <label htmlFor="BetAmount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                    Select An Amount
                                                </label>
                                                <select
                                                    id="BetAmount"
                                                    onChange={(e) => setBetAmount(e.target.value)}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    <option value="">
                                                        Choose A Amount
                                                    </option>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                            <hr className="my-5 border border-blue-500" />
                                            <div className="grid grid-cols-2 gap-4">
                                                <div className="bg-gray-50 dark:bg-gray-700 pb-3">
                                                    <button
                                                        type="button"
                                                        onClick={ ConfirmBet }
                                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                    >
                                                        Confirm Bet
                                                    </button>
                                                </div>
                                                <div className="bg-gray-50 dark:bg-gray-700 pb-3">
                                                    <button
                                                        type="button"
                                                        onClick={toggleBetModal}
                                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )} */}
                </>
            )}
        </>
    );
}