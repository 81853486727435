import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from './Config/config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Preloader } from "./Preloader";
import { BottomNavigation } from './BottomNavigation';

export function AddUPI() {
    const [UPIAccountHolderName, setUPIAccountHolderName] = useState('');
    const [UPI, setUPI] = useState('');
    const [AccountPhoneNumber, setAccountPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false); // Changed to false initially
    const navigate = useNavigate();

    const RedirectToPreviousPage = () => {
        navigate(-1);
    };

    const RedirectToRechargeRecord = () => {
        navigate("/MyUPI");
    };

    const getUserData = async () => {
        setLoading(true); // Start loading
        try {
            const UserName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_user_info',
                Username: UserName
            });

            if (response.data.success === true) {
                setAccountPhoneNumber(response.data.result.username || '');
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false); // End loading
        }
    };

    const addUPI = async (e) => {
        e.preventDefault();
        
        if (UPIAccountHolderName.trim() === "" || UPI.trim() === "" || AccountPhoneNumber.trim() === "") {
            toast.error(`Input Can't Be Empty.`, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setLoading(true); // Start loading

        try {
            const UserName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'process_add_upi',
                Username: UserName,
                UPIAccountHolderName,
                UPI,
                AccountPhoneNumber
            });

            if (response.data.success) {
                toast.success('UPI Has Been Added Successfully!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                
                setTimeout(() => {
                    navigate("/MyUPI");
                }, 2000);
            } else {
                toast.error('Something Went Wrong. Please try again later.', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to add UPI. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <>
            {loading && <Preloader />}
            <BottomNavigation />
            <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 shadow-md">
                <div className="max-w-screen-xl mx-auto p-4 flex items-center justify-between relative">
                    <div className="flex items-center">
                        <button type="button" className="w-6 h-6">
                            <svg onClick={RedirectToPreviousPage} className="w-full h-full text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex- text-center px-4">
                        <img src="./images/RealMoney.png" className="h-8" alt="Logo" />
                    </div>
                    <div className="flex items-center">
                        <svg onClick={RedirectToRechargeRecord} className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
            </nav>
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-2 mb-20">
                    <h1 className="text-3xl text-white text-center font-bold rounded-xl pb-4 pt-3" style={{ fontFamily: 'Varela Round' }}>
                        Add UPI
                    </h1>
                    <form className="mt-8" onSubmit={addUPI}>
                        <div className="grid grid-cols-1 gap-2">
                            <div className="relative z-0 w-full mb-5 group">
                                <input 
                                    type="text" 
                                    onChange={(e) => setUPIAccountHolderName(e.target.value)}
                                    name="floating_upi_account_holder_name" 
                                    id="floating_upi_account_holder_name" 
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="floating_upi_account_holder_name" 
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    UPI/Account Holder Name
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input 
                                    type="text" 
                                    onChange={(e) => setUPI(e.target.value)}
                                    name="floating_upi" 
                                    id="floating_upi" 
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="floating_upi" 
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    Unified Payments Interface (UPI)
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    disabled
                                    readOnly
                                    value={AccountPhoneNumber} 
                                    id="account_phone_number"
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    rows="3"
                                />
                                <label
                                    htmlFor="account_phone_number"
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    Account Phone Number
                                </label>
                            </div>
                            <button
                                className="w-full py-2.5 px-4 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                            >
                                Add UPI
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}