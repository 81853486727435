import React, { useEffect, useState } from 'react';
import { BottomNavigation } from "./BottomNavigation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from './Config/config';
import { Preloader } from "./Preloader";

export function Recharge() {
    const [UserData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [rechargeAmount, setRechargeAmount] = useState('');
    const navigate = useNavigate();

    const RedirectToPreviousPage = () => {
        navigate(-1);
    };

    const RedirectToRechargeRecord = () => {
        navigate("/RechargeRecord");
    };

    const handleAmountClick = (amount) => {
        setRechargeAmount(amount);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        RedirectURL();
    };

    const getUserData = async () => {
        setLoading(true); // Show preloader while fetching data
        try {
            const UserName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_user_info',
                Username: UserName
            });

            if (response.data.success) {
                setUserData(response.data.result);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false); // Hide preloader once fetching is done
        }
    };

    useEffect(() => {
        getUserData();
        
        // const intervalId = setInterval(getUserData, 5000);
        // return () => clearInterval(intervalId);
    }, []);

    const RedirectURL = () => {
        if (rechargeAmount === '' || parseInt(rechargeAmount) < 500) {
            toast.error('Input Empty Or Recharge Value at least 500');
            return;
        }

        const input_amount = parseInt(rechargeAmount);
        navigator.vibrate(100);

        if (input_amount > 499) {
            window.open(
                // `https://realmoney.kheladda.co.in/trova/src/api/RealMoneyAPI/pay.php?user=${UserData.username}&&am=${input_amount}`,
                `https://realmoney.kheladda.co.in/pay.php?user=${UserData.username}&&am=${input_amount}`,
                "_blank"
            );
        } else {
            toast.error("Input Empty Or Recharge Value at least 500", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
    };

    const formatNumberWithCommas = (number) => {
        if (number === undefined || number === null) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            {loading && <Preloader />}
            <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 shadow-md">
                <div className="max-w-screen-xl mx-auto p-4 flex items-center justify-between relative">
                    <div className="flex items-center">
                        <button type="button" className="w-6 h-6">
                            <svg onClick={ RedirectToPreviousPage } className="w-full h-full text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex- text-center px-4">
                        <img src="./images/RealMoney.png" className="h-8" alt="Logo" />
                    </div>
                    <div className="flex items-center">
                        <svg onClick={ RedirectToRechargeRecord } className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
            </nav>

            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-2 mb-20">
                    <h1 className="text-3xl text-white text-center font-bold rounded-xl pb-4 pt-3" style={{ fontFamily: 'Varela Round' }}>
                        Balance :- {formatNumberWithCommas(UserData.balance)}
                    </h1>
                    <form className="mt-8" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-2">
                            <div className="relative z-0 w-full mb-5 group">
                                <input 
                                    type="text" 
                                    name="RechargeAmount" 
                                    id="RechargeAmount" 
                                    value={rechargeAmount}
                                    onChange={(e) => setRechargeAmount(e.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="RechargeAmount" 
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    Recharge Amount
                                </label>
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-1">
                            <div>
                                <button 
                                    type="button"
                                    className="w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
                                    onClick={() => handleAmountClick('500')}
                                >
                                    <span className="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                        ₹ 500
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button 
                                    type="button"
                                    className="w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
                                    onClick={() => handleAmountClick('1000')}
                                >
                                    <span className="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                        ₹ 1,000
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button 
                                    type="button"
                                    className="w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
                                    onClick={() => handleAmountClick('2000')}
                                >
                                    <span className="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                        ₹ 2,000
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button 
                                    type="button"
                                    className="w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
                                    onClick={() => handleAmountClick('5000')}
                                >
                                    <span className="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                        ₹ 5,000
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button 
                                    type="button"
                                    className="w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
                                    onClick={() => handleAmountClick('10000')}
                                >
                                    <span className="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                        ₹ 10,000
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button 
                                    type="button"
                                    className="w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
                                    onClick={() => handleAmountClick('50000')}
                                >
                                    <span className="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                        ₹ 50,000
                                    </span>
                                </button>
                            </div>
                        </div>
                        <button 
                            type="submit" 
                            className="w-full mt-5 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                        >
                            Recharge
                        </button>
                    </form>
                </div>
            </div>
            <BottomNavigation />
            <ToastContainer position="top-center" autoClose={3000} />
        </>
    );
}