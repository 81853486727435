import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from "./Config/config";

export function HeaderForTabs() {
    // const navigate = useNavigate();
    // const [UserData, setUserData] = useState({});
    const [showModal, setShowModal] = useState(false);

    // const getUserData = async () => {
    //     try {
    //         const userName = localStorage.getItem('Username');
    //         const response = await axios.post(`${API_URL}/Index.php`, {
    //             aksi: 'proses_user_info',
    //             Username: userName
    //         });

    //         if (response.data.success) {
    //             setUserData(response.data.result);
    //         } else {
    //             console.error('Error fetching data:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error("Error", error);
    //     }
    // };
    
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    // const RedirectToRecharge = () => {
    //     navigate('./Recharge');
    // };

    useEffect(() => {
        // getUserData();
    }, []);

    return (
        <>
            {/* <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 shadow-md">
                <div className="max-w-screen-xl mx-auto p-4 flex items-center justify-between relative">
                    <div className="flex items-center">
                        <div className="w-8 h-8 rounded-full overflow-hidden" onClick={RedirectToProfile}>
                            <img src="./images/User.png" className="w-full h-full object-cover" alt="User" />
                        </div>
                    </div>
                    <div className="flex- text-center px-4">
                        <img src="./images/RealMoney.png" className="h-8" alt="Logo" />
                    </div>
                    <div className="flex items-center relative">
                        <button type="button" className="relative" onClick={toggleModal}>
                            <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 2a6 6 0 00-6 6v2a6.002 6.002 0 01-4 5.659V17a2 2 0 002 2h16a2 2 0 002-2v-1.341A6.002 6.002 0 0116 10V8a6 6 0 00-6-6zM3 17h14v-1.341A8.01 8.01 0 0010 18a8.01 8.01 0 00-7-2.341V17z" />
                            </svg>
                            <span className="absolute top-0 right-0 w-3.5 h-3.5 bg-red-500 border-2 border-white dark:border-gray-900 rounded-full"></span>
                        </button>
                    </div>
                </div>
            </nav> */}

            <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 shadow-md">
                <div className="max-w-screen-xl mx-auto p-4 flex items-center justify-between relative">
                    <div className="flex items-center">
                        <img src="./images/RealMoney.png" className="h-8" alt="Logo" />
                    </div>
                    <div className="flex items-center relative">
                        <div className="relative flex items-center space-x-2">
                            <button type="button" className="relative" onClick={toggleModal}>
                                <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 2a6 6 0 00-6 6v2a6.002 6.002 0 01-4 5.659V17a2 2 0 002 2h16a2 2 0 002-2v-1.341A6.002 6.002 0 0116 10V8a6 6 0 00-6-6zM3 17h14v-1.341A8.01 8.01 0 0010 18a8.01 8.01 0 00-7-2.341V17z" />
                                </svg>
                                <span className="absolute top-0 right-0 w-3.5 h-3.5 bg-red-500 border-2 border-white dark:border-gray-900 rounded-full"></span>
                            </button>
                            {/* <span className="text-gray-500 dark:text-gray-400 font-medium">
                                ₹{UserData.balance || 0}
                            </span>
                            <button 
                                type="button" 
                                className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                onClick={RedirectToRecharge}
                            >
                                Add
                            </button> */}
                        </div>
                    </div>
                </div>
            </nav>

            <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600 shadow-md">
                <div className="grid h-full max-w-lg grid-cols-3 mx-auto font-medium">
                    <NavLink 
                        to="/Promotion"
                        className={({ isActive }) => `inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group ${isActive ? 'text-blue-600 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'}`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                            <path d="M9.375 3a1.875 1.875 0 0 0 0 3.75h1.875v4.5H3.375A1.875 1.875 0 0 1 1.5 9.375v-.75c0-1.036.84-1.875 1.875-1.875h3.193A3.375 3.375 0 0 1 12 2.753a3.375 3.375 0 0 1 5.432 3.997h3.943c1.035 0 1.875.84 1.875 1.875v.75c0 1.036-.84 1.875-1.875 1.875H12.75v-4.5h1.875a1.875 1.875 0 1 0-1.875-1.875V6.75h-1.5V4.875C11.25 3.839 10.41 3 9.375 3ZM11.25 12.75H3v6.75a2.25 2.25 0 0 0 2.25 2.25h6v-9ZM12.75 12.75v9h6.75a2.25 2.25 0 0 0 2.25-2.25v-6.75h-9Z" />
                        </svg>
                        {/* <svg className="w-5 h-5 mb-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z"/>
                            <path d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z"/>
                        </svg> */}
                        <span className="text-sm">Referral</span>
                    </NavLink>
                    <NavLink 
                        to="/"
                        className={({ isActive }) => `inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group ${isActive ? 'text-blue-600 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'}`}
                    >
                        <svg className="w-5 h-5 mb-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                        </svg>
                        <span className="text-sm">Home</span>
                    </NavLink>
                    <NavLink 
                        to="/Profile"
                        className={({ isActive }) => `inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group ${isActive ? 'text-blue-600 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'}`}
                    >   
                        <svg className="w-5 h-5 mb-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                        </svg>
                        <span className="text-sm">Profile</span>
                    </NavLink>
                </div> 
            </div>
            
            {showModal && (
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                                        </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Notification</h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500 dark:text-gray-400">Invite Your Friends & Family, And Get Upto 20% Bonus On Your First Deposite.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"> */}
                            <div className="px-4 py-3 justify-center items-center text-center">
                                <button type="button" onClick={ toggleModal } className="w-full inline-flex justify-center items-center text-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}