import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "./Config/config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Preloader } from "./Preloader";

export function SignIn() {
    const navigate = useNavigate();
    
    const [UserName, setUsername] = useState("");
    const [Password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // Initialize loading state
    
    const getFormData = async (e) => {
        e.preventDefault();
        
        if (UserName === "" || Password === "") {
            toast.error(`Input Can't Be Empty.`, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        if (UserName.length !== 10) {
            toast.error('Mobile number must be exactly 10 digits.', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        setLoading(true); // Show preloader while fetching data

        try {
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_login',
                Username: UserName,
                Password: Password
            });

            if (response.data.success === true) {
                const loginDetails = JSON.stringify(response.data);

                localStorage.setItem("loginStatus", true);
                localStorage.setItem("RealMoneyloginDetails", loginDetails);
                localStorage.setItem("Username", UserName);
                localStorage.setItem("Password", Password);

                toast.success('Login Successfully!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            } else {
                toast.error('Wrong Credentials!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to login. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false); // Hide preloader once fetching is done
        }
    }

    const RedirectToSignUpPage = () => {
        navigate("/SignUp");
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    return (
        <>
            <ToastContainer />
            {loading && <Preloader />} {/* Show preloader while loading */}
            <div className="min-h-screen flex items-center bg-[#21275B] justify-center py-12 px-4">
                <div className="max-w-md w-full space-y-8">
                    <div className="bg-[#343b6e] rounded-xl shadow-lg">
                        <div className="mx-2 px-1 py-5">
                            <form className="mt-6" onSubmit={getFormData}>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="UserName" onChange={(e) => setUsername(e.target.value)} id="UserName" className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="UserName" className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Mobile Number</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type={showPassword ? "text" : "password"} name="Password" onChange={(e) => setPassword(e.target.value)} id="Password" className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                    <label htmlFor="Password" className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                        {showPassword ? (
                                            <VisibilityOff className="h-6 text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                        ) : (
                                            <Visibility className="h-6 text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                        )}
                                    </div>
                                </div>
                                <div className="mb-4 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input id="link-checkbox" type="checkbox" defaultChecked value="" className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium text-gray-300">Keep me logged In.</label>
                                    </div>
                                    <span className="text-sm font-medium text-blue-600 hover:text-blue-600 dark:text-white">
                                        Forgot Password?
                                    </span>
                                </div>
                                <button type="submit" className="mb-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center">
                                    Login
                                </button>
                            </form>
                            <div className="items-center justify-center text-center">
                                <span className="text-sm font-medium text-gray-300">
                                    Create a new account?
                                </span>
                                <span onClick={RedirectToSignUpPage} className="ml-1 text-sm font-medium text-blue-600 dark:text-white">
                                    Sign Up!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}