import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HeaderForTabs } from "./HeaderForTabs";
import { API_URL } from './Config/config';
import axios from 'axios';
import { Preloader } from "./Preloader";

export function Promotion() {
    const [UserData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleCopy = () => {
        toast.success('Referral Code Link Has Been Copied Successfully!', {
            position: "top-center",
            hideProgressBar: false,
        });
    };

    const getUserData = async () => {
        setLoading(true);
        
        try {
            const UserName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_user_info',
                Username: UserName
            });

            if (response.data.success) {
                setUserData(response.data.result);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserData();
        
        // const intervalId = setInterval(getUserData, 5000);
        // return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            {loading && <Preloader />}
            <HeaderForTabs />
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-2 mb-20">
                    <h1 className="text-3xl text-white text-center font-bold rounded-xl pb-4 pt-3" style={{ fontFamily: 'Varela Round' }}>
                        Referral Code
                    </h1>
                    <form className="mt-8">
                        <div className="grid grid-cols-1 gap-2">
                            <div className="relative z-0 w-full mb-5 group">
                                <input 
                                    type="text" 
                                    disabled 
                                    readOnly 
                                    value={`${UserData.usercode || ''}`} 
                                    name="floating_first_name" 
                                    id="floating_first_name" 
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="floating_first_name" 
                                    className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    My Referral Code Code
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    disabled
                                    readOnly
                                    value={`https://realmoney.kheladda.co.in/#/signup?r_code=${UserData.usercode || ''}`} 
                                    id="referral_link"
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    rows="3"
                                    required
                                />
                                <label
                                    htmlFor="referral_link"
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    My Referral Code Link
                                </label>
                            </div>
                            <CopyToClipboard text={`https://realmoney.kheladda.co.in/#/SignUp?r_code=${UserData.usercode || ''}`} onCopy={handleCopy}>
                                <button
                                    type="button"
                                    className="w-full py-2.5 px-4 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                >
                                    Copy Referral Code
                                </button>
                            </CopyToClipboard>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}