// import BannerCarousel from "./BannerCarousel";
import { HeaderForTabs } from "./HeaderForTabs";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "./Config/config";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Preloader } from "./Preloader";

export function Home() {
    const [GameCategory, setGameCategory] = useState([]);
    const [PeriodId, setPeriodId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedGameId, setSelectedGameId] = useState(null);
    const [betAmount, setBetAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [UserData, setUserData] = useState({});
    const navigate = useNavigate();

    const getPeriodId = async () => {
        try {
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_fetch_periodId'
            });
            
            if(response.data.success === true) {
                setPeriodId(response.data.Period_Id);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    const getGameCategory = async () => {
        try {
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_fetch_game_category'
            });
            
            if(response.data.success === true) {
                setGameCategory(response.data.result);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handleRedirect = (game_id, amount) => {
        setSelectedGameId(game_id);
        setBetAmount(amount);
        setShowModal(true);
    };

    const ConfirmBet = async () => {
        try {
            if (betAmount < 10) {
                toast.error('Enter a minimum bet amount of 10...!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                
                return;
            }
            
            const loginDetails = localStorage.getItem('RealMoneyloginDetails');
            if(!loginDetails) {
                toast.error('Login details not found!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });

                navigate('./SignIn');
                return;
            }
            
            const parsedDetails = JSON.parse(loginDetails);
            
            const UserId = parsedDetails?.result?.id;
            const UserName = parsedDetails?.result?.Username;
            
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_betting',
                UserId: UserId,
                Username: UserName,
                GameId: selectedGameId,
                PeriodId: PeriodId,
                BettingAmount: betAmount
            });

            if (response.data.success === true) {
                toast.success('Bet Has Been Confirmed Successfully...!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                
                setTimeout(() => {
                    navigate('/betting', { state: { game_id: selectedGameId } });
                    setShowModal(false);
                    setSelectedGameId(null);
                }, 1500);
            } else if (response.data.result === "Insufficient Balance") {
                toast.error('Insufficient Balance...!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            } else if(response.data.result === "You have already placed a bet for this game and period.") {
                toast.error('You have already placed a bet for this Table...!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                
                setTimeout(() => {
                    navigate('/betting', { state: { game_id: selectedGameId } });
                    setShowModal(false);
                    setSelectedGameId(null);
                }, 1500);
            } else {
                toast.error('Something Went Wrong...!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error", error);
            toast.error('An error occurred. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const getUserData = async () => {
        try {
            const userName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_user_info',
                Username: userName
            });

            if (response.data.success) {
                setUserData(response.data.result);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedGameId(null);
        setBetAmount(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            await getGameCategory();
            await getPeriodId();
            setLoading(false);
        };

        fetchData();
        
        const intervalId = setInterval(() => {
            getPeriodId();
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        getUserData();
    }, []);

    const RedirectToRecharge = () => {
        navigate('./Recharge');
    };

    const RedirectToWithdraw = () => {
        navigate('./Withdrawal');
    };

    return (
        <>
            {loading && <Preloader />}
            <HeaderForTabs />
            <ToastContainer position="top-center" autoClose={3000} />
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="mt-[65px] mb-20">
                    <div className="grid grid-cols-3 items-center gap-4 p-4">
                        <div className="col-span-2">
                            <p className="text-gray-300">
                                Balance
                            </p>
                            <div className="flex items-center space-x-1">
                                <p className="text-2xl font-bold text-white">₹{UserData.balance || 0}</p>
                            </div>
                            <p className="text-gray-300">ID: {UserData.usercode || ''}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                            <button onClick={RedirectToRecharge} className="bg-blue-500 font-bold text-white rounded-lg px-4 py-2 flex justify-center items-center">
                                Recharge
                            </button>
                            <button onClick={RedirectToWithdraw} className="bg-gray-200 font-bold text-gray-500 rounded-lg px-4 py-2 flex justify-center items-center">
                                Withdraw
                            </button>
                        </div>
                    </div>
                    
                    <hr className="mb-3 border border-blue-500 mx-2" />

                    {/* <BannerCarousel /> */}
                    <div className="container mx-auto px-2">
                        <h1 className="text-lg font-bold text-white" style={{ fontFamily: 'Varela Round' }}>Explore Our Gamer...</h1>
                        <div className="grid grid-cols-2 gap-4 mt-1">
                            {GameCategory.map((item, index) => (
                                <div 
                                    key={index} 
                                    className="flex flex-col items-center justify-center text-center border-2 border-gray-400 rounded-xl pt-3 px-2 bg-[#343b6e] shadow-lg cursor-pointer"
                                    onClick={() => handleRedirect(item.id, item.bet_amount)}
                                >
                                    <img src={item.game_category_image} alt="10 Members" className="w-full rounded-xl mb-1" />
                                    <p className="font-semibold text-white" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                        { item.game_title }
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            
            {showModal && (
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-2 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white dark:bg-gray-800 px-3 pt-5 pb-2 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-center sm:justify-center">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:flex sm:items-center">
                                        <p className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                            Are you sure you want to bet for {GameCategory.find(item => item.id === selectedGameId)?.game_title}? Please confirm.
                                        </p>
                                    </div>
                                </div>
                                <hr className="my-5 border border-blue-500" />
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="bg-gray-50 dark:bg-gray-700 pb-3">
                                        <button
                                            type="button"
                                            onClick={ ConfirmBet }
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                        >
                                            Yes, Bet Now
                                        </button>
                                    </div>
                                    <div className="bg-gray-50 dark:bg-gray-700 pb-3">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            No, Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}