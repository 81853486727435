import axios from "axios";
import { Header } from "./Header";
import { API_URL } from './Config/config';
import { useEffect, useState } from "react";
import { FaUser, FaWallet, FaPhoneAlt } from 'react-icons/fa';
import { Preloader } from "./Preloader";

export function MyUPI() {
    const [userData, setUserData] = useState([]);
    const [dataError, setDataError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const getUserData = async () => {
        setIsLoading(true);

        try {
            const userName = localStorage.getItem('Username');
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_user_info',
                Username: userName
            });

            if (response.data.success === true) {
                const result = response.data.result;
                
                if (
                    !result || 
                    !result.name || 
                    !result.upi || 
                    !result.mobile || 
                    result.name.trim() === "" || 
                    result.upi.trim() === "" || 
                    result.mobile.trim() === ""
                ) {
                    setDataError(true);
                } else {
                    setUserData(result);
                    setDataError(false);
                }
            } else {
                console.error('Error fetching data:', response.data.error);
                setDataError(true);
            }
        } catch (error) {
            console.error("Error", error);
            setDataError(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return (
        <>
            <Header />
            {isLoading && <Preloader />} 
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-1.5 mb-20">
                    <ul>
                        {isLoading ? (
                            <li className="bg-[#343b6e] py-3 px-2 mt-5 rounded-lg shadow-lg border-b-2 border-blue-500">
                                <div className="col-span-12 text-center">
                                    Loading...
                                </div>
                            </li>
                        ) : dataError ? (
                            <li className="bg-[#343b6e] py-3 px-2 mt-5 rounded-lg shadow-lg border-b-2 border-red-500">
                                <div className="col-span-12 text-center">
                                    No data available
                                </div>
                            </li>
                        ) : (
                            <li 
                                className="bg-[#343b6e] font-semibold py-3 px-2 mt-5 rounded-lg shadow-lg border-b-4 border-green-500"
                            >
                                <div className="grid grid-cols-12 gap-2">
                                    <div className="col-span-9 flex items-center text-white">
                                        <FaUser className="text-green-500 mr-2" />
                                        {capitalizeFirstLetter(userData.name)}
                                    </div>
                                    <div className="col-span-3 text-right">
                                        <span className="text-green-500 bg-green-50 border border-green-400 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
                                            Success
                                        </span>
                                    </div>
                                    <div className="col-span-7 mt-2 flex items-center text-white">
                                        <FaWallet className="text-green-500 mr-2" />
                                        {userData.upi}
                                    </div>
                                    <div className="col-span-5 justify-end mt-2 flex items-center text-white">
                                        <FaPhoneAlt className="text-green-500 mr-2" />
                                        {userData.mobile}
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
}