import axios from "axios";
import { Header } from "./Header";
import { API_URL } from './Config/config';
import { useEffect, useState } from "react";
import { Preloader } from "./Preloader";

export function History() {
    const [userTransactionRecordData, setUserTransactionRecordData] = useState([]);
    const [pagination, setPagination] = useState(1);
    const [loading, setLoading] = useState(true);

    const getUserTransactionRecordData = async () => {
        setLoading(true);
        try {
            const userName = localStorage.getItem('Username');
            
            const response = await axios.post(`${API_URL}/Index.php`, {
                aksi: 'proses_my_bet_record',
                Page: pagination,
                Username: userName
            });
            
            if (response.data.success) {
                const myRecordDetails = response.data.result;
                setUserTransactionRecordData(myRecordDetails);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserTransactionRecordData();

        // const intervalId = setInterval(getUserTransactionRecordData, 5000);
        // return () => clearInterval(intervalId);
    }, [pagination]);

    const getStatusClassesForText = (status) => {
        switch (status) {
            case 'Success':
                return 'text-green-500 bg-green-50 border border-green-400';
            case 'Failed':
                return 'text-red-500 bg-red-50 border border-red-400';
            case 'Wait':
                return 'text-yellow-500 bg-yellow-50 border border-yellow-400';
            default:
                return 'text-blue-500 bg-blue-50 border border-blue-400';
        }
    };

    const getStatusClassesForBorder = (status) => {
        switch (status) {
            case 'Success':
                return 'border-green-500';
            case 'Failed':
                return 'border-red-500';
            case 'Wait':
                return 'border-yellow-500';
            default:
                return 'border-blue-500';
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const formatNumberWithCommas = (number) => {
        if (number === undefined || number === null) {
            return '';
        }

        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            {loading && <Preloader />}
            <Header />
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-1.5 mb-20">
                    <div>
                        <ul>
                            {userTransactionRecordData.length > 0 ? (
                                userTransactionRecordData.map((item, index) => (
                                    <li key={index} className={`bg-[#343b6e] font-semibold py-3 px-2 mt-5 rounded-lg shadow-lg border-b-4 ${getStatusClassesForBorder(item.status)}`}>
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-9 text-white">
                                                ₹ {formatNumberWithCommas(item.betting_amount)}
                                            </div>
                                            <div className="col-span-3 text-right">
                                                <span className={`${getStatusClassesForText(item.status)} text-sm font-medium me-2 px-2.5 py-0.5 rounded`}>
                                                    {capitalizeFirstLetter(item.status)}
                                                </span>
                                            </div>
                                            <div className="col-span-7 mt-2 text-white">
                                                Game:- {item.game_title}
                                            </div>
                                            <div className="col-span-5 text-right mt-2 text-white">
                                                Bet Placed
                                            </div>
                                            <div className="col-span-12 mt-2 text-white">
                                                {item.date_time}
                                            </div>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <li className="bg-[#343b6e] py-3 px-2 mt-5 rounded-lg shadow-lg border-b-2 border-red-500">
                                    <div className="col-span-12 text-white">
                                        No data available
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="pagination-controls flex justify-between items-center mt-6">
                        <button 
                            onClick={() => setPagination(pagination - 1)}
                            disabled={pagination === 1}
                            className={`px-4 py-2 rounded-lg flex items-center ${pagination === 1 ? 'bg-gray-200 text-gray-700 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                        >
                            <svg className={`w-6 h-6 inline-block mr-2 ${pagination === 1 ? 'text-gray-500' : 'text-white'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                            </svg>
                            Previous
                        </button>
                        <button 
                            onClick={() => setPagination(pagination + 1)}
                            disabled={userTransactionRecordData.length === 0}
                            className={`px-4 py-2 rounded-lg flex items-center ${userTransactionRecordData.length === 0 ? 'bg-gray-200 text-gray-700 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                        >
                            Next
                            <svg className={`w-6 h-6 inline-block mr-2 ${userTransactionRecordData.length === 0 ? 'text-gray-500' : 'text-white'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}