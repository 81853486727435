import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from './Config/config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Preloader } from "./Preloader";
import { Header } from './Header';

export function ResetPassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handlePasswordReset = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        setTimeout(async () => {

            if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
                toast.error('All fields are required.', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                setIsSubmitting(false);
                setLoading(false);

                return;
            }

            if (newPassword !== confirmPassword) {
                toast.error('New password and confirmation do not match.', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                setIsSubmitting(false);
                setLoading(false);
                
                return;
            }

            try {
                const UserName = localStorage.getItem('Username');

                const response = await axios.post(`${API_URL}/Index.php`, {
                    aksi: 'ResetPassword',
                    UserName: UserName,
                    OldPassword: oldPassword,
                    NewPassword: newPassword
                });

                const result = response.data;

                if (result.success) {
                    toast.success('Password has been reset successfully!', {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });

                    localStorage.removeItem("loginStatus");
                    localStorage.removeItem("RealMoneyloginDetails");
                    localStorage.removeItem("Username");
                    localStorage.removeItem("Password");

                    setTimeout(() => {
                        navigate("/SignIn");
                    }, 2000);
                } else {
                    let errorMessage = 'Failed to reset password. Please try again later.';
                    if (result.result === 'Wrong Old Password') {
                        errorMessage = 'Wrong old password. Please try again.';
                    } else if (result.result === 'User Not Found') {
                        errorMessage = 'User not found. Please check your username.';
                    }

                    toast.error(errorMessage, {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (error) {
                console.error('Error resetting password:', error);

                toast.error('Failed to reset password. Please try again later.', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            } finally {
                setIsSubmitting(false);
                setLoading(false);
            }
        }, 1500);
    };

    return (
        <>
            {loading && <Preloader />}
            <Header />
            <div className="max-w-md mx-auto bg-[#21275B] min-h-screen pb-1 pt-1">
                <div className="items-center justify-center mt-20 px-2 mb-20">
                    <h1 className="text-3xl text-white text-center font-bold rounded-xl pb-4 pt-3" style={{ fontFamily: 'Varela Round' }}>
                        Reset Password
                    </h1>
                    <form className="mt-8" onSubmit={handlePasswordReset}>
                        <div className="grid grid-cols-1 gap-2">
                            <div className="relative z-0 w-full mb-5 group">
                                <input 
                                    type="password" 
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    name="floating_old_password" 
                                    id="floating_old_password" 
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="floating_old_password" 
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    Old Password
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input 
                                    type="password" 
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    name="floating_new_password" 
                                    id="floating_new_password" 
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="floating_new_password" 
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    New Password
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input 
                                    type="password" 
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    name="floating_confirm_password" 
                                    id="floating_confirm_password" 
                                    className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="floating_confirm_password" 
                                    className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    Confirm New Password
                                </label>
                            </div>
                            <button
                                type="submit"
                                className={`py-2 px-4 flex justify-center items-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg max-w-md ${isSubmitting ? 'cursor-wait' : ''}`}
                            >
                                {isSubmitting ? (
                                    <>
                                        <svg width="20" height="20" fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                                        </svg>
                                        Loading
                                    </>
                                ) : (
                                    'Reset Password'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}